<template>
  <div class="s-layout" :style="colorObject">
    <div class="s-container">
      <Header
        :meta="meta"
        :page-title="moduleConfig.page_title"
        :page-image="moduleConfig.page_image"
        :page-kv-image="moduleConfig.page_kv_image"
        :header-section="moduleConfig.header_section"
      />

      <div class="content">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "@/components/Page/Liff/Shared/HeaderV3";
import themeColor from "@/mixins/liff/themeColor";
import mixinStaff from "@/mixins/liff/staff";

export default {
  mixins: [themeColor, mixinStaff],
  components: {
    Header,
  },
  computed: {
    ...mapGetters({
      meta: "liffGeneral/meta",
      themeConfig: "liffGeneral/themeConfig",
      getModule: "liffModule/getModule",
    }),
    moduleConfig() {
      return this.getModule("liff_staff").module_config || {};
    },
  },
  data() {
    return {
      themeConfigPage: "staff",
    };
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "../../../assets/css/liff/main.css";
@import "../../../assets/css/liff/liff_init_theme_config.css";

.s-layout,
.s-layout *,
.s-layout *:before,
.s-layout *:after {
  --s-secondary: #de006f;
  --s-warning: #ed6c00;
  --s-danger: #fe0000;
  --s-gray-darker: #363636;
  --s-gray-dark: #2c2c2e;
  --s-gray: #979797 !important;
  --s-gray-light: #c0c4cc;
  --s-gray-lighter: #f2f2f7;
}

.s-layout {
  color: var(--s-gray-dark);
  background-color: var(--liff-layout_bg_color);
  min-height: 100vh;
}

.s-container {
  padding-bottom: 1.5rem;
}

.content {
  // height: calc(100vh - 52px - 56px);
  // overflow-y: auto;
  padding: 0px 12px 16px 12px;
  background: var(--liff-content_bg_color);
}

::v-deep .page {
  &__title {
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 10px;
    text-align: center;
    color: var(--s-primary);
  }

  &__desc {
    margin-bottom: 10px;
    margin-top: 10px;
  }
}


::v-deep .field {
  &:not(:last-of-type) {
    margin-bottom: 24px;
  }
  &__label {
    font-weight: 400;
    font-size: 14px;
    color: var(--liff-primary_text_color);
    margin-bottom: 8px;
    display: block;
  }

  &__desc {
    color: var(--liff-secondary_text_color);
    font-size: 12px;
    line-height: 1;
    margin-top: 4px;
  }

  &__input {
    font-weight: 500;
    font-size: 16px;
    color: var(--liff-primary_text_color);
    border: none;
    border-bottom: 1px solid #e5e5ea;
    background: transparent;
    padding-bottom: 8px;
    width: 100%;
    border: 1px solid #e5e5ea;
    border-radius: 5px;
    padding: 8px 12px;
    &::placeholder {
      color: #b7b7b7;
    }
    &:focus {
      border: 1px solid var(--liff-primary_text_color);
    }
    &.invalid {
      border: 1px solid #fe0000;
    }
  }

  &--required {
    &:after {
      content: "*";
      position: relative;
      font-family: "PingFang TC";
      color: var(--s-primary);
      margin-left: 2px;
    }
  }

  &__select-group {
    display: flex;
    > * {
      width: 100%;
      &:not(:last-of-type) {
        margin-right: 8px;
      }
    }
    &.invalid {
      border-bottom: 1px solid #fe0000;
    }
  }
  input.uppercase {
    text-transform: uppercase;
  }
}


::v-deep .b-overlay-wrap{
  margin: -16px !important;
  padding: 0px 12px 16px 12px;
}

</style>
